
<template lang="pug">
#day-one-completion.step-component
  icon.icon(:data="require('@icon/hands-clapping.svg')", :original="true")
  div(v-html="header")
  #message(v-if="cantProceed")
    div(v-html="body")
  p.continue(v-if="cantProceed", @click="cantProceed ? openConfirmationModal() : gotoNext()") Proceed to Day 2 Immediately
  button.button(v-else) Continue to Day 2
</template>

<script>
import AmplitudeAPI from '@/utils/amplitude'
import Vue from 'vue'

export default {
  data() {
    return {
      now: new Date(),
    }
  },
  computed: {
    cantProceed() {
      const recommendedDate = new Date(localStorage.getItem('dayOneCompletedTime'))
      recommendedDate.setHours(recommendedDate.getHours() + 2)
      return recommendedDate > this.now
    },
    header() {
      const dayOne = "<h1>You're Done for Today!<br>Return Tomorrow Morning</h1>"
      const dayTwo = '<h1> Welcome back to Day 2!</h1>'
      return this.cantProceed ? dayOne : dayTwo
    },
    body() {
      return `<p class='delete-app'><strong>You may now close the app. Please do not delete.</strong></p><p>Return to Kit app <strong>tomorrow before 10AM</strong> to collect 2nd sample.</p>`
    },
  },
  created() {
    if (!localStorage.getItem('dayOneCompletedTime')) {
      localStorage.setItem('dayOneCompletedTime', this.now)
      AmplitudeAPI.logEvent('DayOneCompleted', {
        kit_type: this.$store.getters['user/getKitType'],
        medplum_id: this.$store.getters['user/getMedplumOrderId'],
        barcode: this.$store.getters['user/getBarcodeId'],
        sku: this.$store.getters['user/getSku'],
        linked_amplitude_id: this.$route.query.q,
        customer: this.$store.getters['user/getConfigKeys'] ? this.$store.getters['user/getConfigKeys'].carrier : null,
        end_date: new Date().toISOString(),
        source: this.$route.query.src,
      })
      Vue.axios.post('/app-checkpoint', {
        orderId: this.$store.getters['user/getMedplumOrderId'],
        checkpointPosition: 1,
      })
    }
  },
  mounted() {
    document.addEventListener(
      'visibilitychange',
      function () {
        this.now = new Date()
        if (document.visibilityState == 'visible' && this.proceedToDayTwo()) {
          this.gotoNext()
        }
      }.bind(this)
    )
  },
  methods: {
    proceedToDayTwo,
    openConfirmationModal,
    gotoNext,
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function proceedToDayTwo() {
  var todaysDate = new Date()
  var prevDate = new Date(localStorage.getItem('dayOneCompletedTime'))
  return todaysDate.getDate() > prevDate.getDate()
}
function openConfirmationModal() {
  this.$modal.open('DayTwoWarning', {
    onAction: this.gotoNext,
  })
}
function gotoNext() {
  this.$emit('complete', true)
  this.$modal.closeAll()
}
</script>
